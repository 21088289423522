import { useContext, useRef} from 'preact/hooks';
import { CartContext } from '../../context/shopContext.tsx';
import { formatter } from '../../utils/helpers.ts';
import { Router } from 'preact-router';
import { Link } from 'preact-router/match';


export default function MiniCart({ cart }) {
  const cancelButtonRef = useRef()

  const { cartOpen, setCartOpen, checkoutUrl, removeCartItem, clearCart, cartLoading, incrementCartItem, decrementCartItem } = useContext(CartContext)

  let cartTotal = 0
  cart.map(item => {
    cartTotal += item?.variantPrice * item?.variantQuantity
  })
  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }
  
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    setCartOpen(!cartOpen);
  }
 return (
<div id="mySidenav" class="sidenav cart-container">
<div class="cart-top">
<div class="cart-col-container">
<div class="cart-col-r">
<a
                          ref={cancelButtonRef}
                          style="font-size: medium; font-weight: 700;"
                          onClick={() => {setCartOpen(false); closeNav();}}
                        >
                          X
                        </a>
</div>
<div class="cart-col-l mtb-5">
  <h2>Warenkorb</h2>
</div>
</div>
{cart.length > 0 ? 
<div class="wahl mtb-5"><svg style="filter: invert(25%) sepia(83%) saturate(1756%) hue-rotate(94deg) brightness(92%) contrast(107%);" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><path d="M23.873 9.81c.087-.251.127-.509.127-.764 0-.77-.38-1.514-1.055-1.981-2.153-1.492-1.868-1.117-2.679-3.543-.34-1.013-1.319-1.697-2.424-1.697h-.007c-2.653.009-2.193.151-4.334-1.354-.446-.314-.974-.471-1.501-.471s-1.055.157-1.502.471c-2.156 1.515-1.686 1.362-4.334 1.353h-.007c-1.104 0-2.084.685-2.422 1.697-.812 2.432-.534 2.056-2.678 3.544-.677.469-1.057 1.212-1.057 1.983 0 .254.042.511.127.762.831 2.428.829 1.962 0 4.38-.085.251-.127.507-.127.762 0 .77.38 1.514 1.057 1.983 2.146 1.49 1.868 1.113 2.679 3.543.338 1.013 1.317 1.697 2.422 1.697h.007c2.653-.009 2.193-.152 4.334 1.353.446.314.974.472 1.501.472s1.055-.158 1.502-.471c2.141-1.504 1.679-1.362 4.334-1.353h.007c1.104 0 2.084-.685 2.424-1.697.811-2.427.525-2.052 2.679-3.543.674-.469 1.054-1.213 1.054-1.983 0-.254-.04-.512-.127-.763-.831-2.428-.827-1.963 0-4.38zm-13.373 6.94l-4.5-4.364 1.858-1.857 2.642 2.505 5.642-5.783 1.858 1.857-7.5 7.642z"></path></svg><strong>&nbsp;Gute Wahl!&nbsp;</strong> Hier sind deine Produkte:</div> : null
}
</div>
{
cart.length > 0 ?
<div class="cart-mid mtb-5">
 {cart.map((product) => (
<div class="cart-col-container" key={product.id + Math.random()}>
<div class="cart-col-l">
  
                           <div>
                                    <img
                                    style="width: 100px; height: 100px;"
                                      src={product.image}
                                      alt={product.title}
                                    />
                                  </div>
</div>
<div class="cart-col-m">
                                    <div>
                                      <div>
                                        <strong>
                                          <Link href={`/products/${product.handle}`} passHref>
                                            <a onClick={() => setCartOpen(false)}>{product.title}</a>
                                          </Link>
                                        </strong>
                                       <strong> <span>{formatter.format(product.variantPrice)}</span></strong>
                                      </div>
                                    </div>
                                   
                                
                                      <div class="QuantitySelector">
                                        <a 
                                
                                          onClick={() => decrementCartItem(product)}
                                          disabled={cartLoading}
                                        >
<strong>
                                          -
</strong>        
                                </a>
                                       <strong> <span >{product.variantQuantity}</span></strong>
                                        <a 
                                          onClick={() => incrementCartItem(product)}
                                          disabled={cartLoading}
                                        >
<strong>
                                          +
</strong>        
                                </a>
                                      </div>
</div>
<div class="cart-col-r">
                                      <div>
                                        <a
                                          style="text-decoration: underline!important;"
                                          onClick={() => removeCartItem(product.id)}
                                          
                                          disabled={cartLoading}
                                        >
                                          Entfernen
                                        </a>
                                   
 </div>
</div>
</div>
                              ))}
                            </div> :
                            <div>
                            <strong>  <p>Es ist nichts im Warenkorb!</p></strong>
                            </div>
}
{
cart.length > 0 ?
<div class="cart-bot mtb-5">
<div class="cart-col-container">
<div class="cart-col-l">
                        
<div>
<strong> <span>Zwischensumme</span></strong>
</div>
</div>
<div class="cart-col-r">
<strong> <span>{formatter.format(cartTotal)}</span></strong>
</div>
</div>                       
                        <p style="font-size: 10px;">Versandkosten und Rabatte werden im Checkout verrechnet.</p>
                        <div>
                          <a
class="btnbtn mtb-5"
                            href={checkoutUrl}
                          >
                            JETZT SICHER ZUR KASSE!
                          </a>
<img style="width: 100%;" src="../../assets/pf-c36b97a1--zahlungsmethoden1.png"/>
                        </div>

                        <div class="cart-col-container mtb-5">
                         <div class="cart-col-l">
                            <a
                              style="text-decoration: underline!important;"
                              onClick={() => clearCart()}
                            >
                              Warenkorb leeren
                            </a>
</div>
<div class="cart-col-m mtb-5">
                            {' '}oder{' '}
</div>
<div class="cart-col-r">
                            <a
                              style="text-decoration: underline;"
                              
                              onClick={() => {setCartOpen(false); closeNav();}}
                            >
                              weiter Einkaufen<span aria-hidden="true"> &rarr;</span>
                            </a>
                         </div>
                        </div>

</div> : null
}
</div>
    
  )
}



